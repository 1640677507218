import { Link, styled } from "@mui/material";
import AnimatedParentDiv from "../AnimatedParentDiv";

const NavLink = styled(Link)(({ theme, active }) => ({
  color: "#111111",
  letterSpacing: "-0.025em",
  paddingBottom: "3px",
  fontSize: "1em",
  fontWeight: 900,
  variant: "nav",
  opacity: active ? 1 : 0.9,
  textDecoration: "none",
  transition: "opacity 0.3s",
  display: "inline-block", // Add this to ensure transform-origin works properly
  transformOrigin: "left center", // Add this to make scaling start from left
  "&:hover": {
    color: theme.palette.primary.main,
    opacity: 1,
    textDecoration: "none",
  },
}));

const AnimatedNavLink = ({
  children,
  duration = 0.8,
  delay = 0,
  active,
  animationState,
  href,
}) => {
  return (
    <AnimatedParentDiv
      duration={duration}
      delay={delay}
      active={active}
      animationState={animationState}
    >
      <NavLink href={href} active={active} variant="nav">
        {children}
      </NavLink>
    </AnimatedParentDiv>
  );
};

export default AnimatedNavLink;
