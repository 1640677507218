import { motion } from "framer-motion";
import { Box } from "@mui/material";

const AnimatedParentDiv = ({
  children,
  duration = 0.8,
  delay = 0,
  active,
  animationState,
}) => {
  return (
    <Box sx={{ overflow: "hidden" }}>
      <motion.div
        style={{
          transformStyle: "preserve-3d",
          display: "inline-block",
          transformOrigin: "left center",
        }}
        initial={{
          transform: "translate3d(0px, 120%, 0px) scale3d(1, 1, 1)",
        }}
        animate={{
          transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1)`,
        }}
        transition={{
          duration,
          ease: [0.16, 1, 0.3, 1],
          delay,
        }}
      >
        <motion.div
          style={{
            transformOrigin: "left center",
          }}
          animate={
            animationState
              ? {
                  fontSize: active ? "1.7em" : "1em",
                }
              : { fontSize: 1 }
          }
          transition={{
            duration: 0.4,
            ease: "easeOut",
          }}
        >
          {children}
        </motion.div>
      </motion.div>
    </Box>
  );
};

export default AnimatedParentDiv;
