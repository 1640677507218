import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#111111",
    },
    text: {
      primary: "#111111",
      secondary: "rgba(17, 17, 17, 0.5)",
      inactive: "rgba(17, 17, 17, 0.5)", // ONLY NEW LINE ADDED
    },
    background: {
      default: "#f7fafa",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: "2vw",
    h1: {
      letterSpacing: "-0.02em",
      marginTop: 0,
      marginBottom: 0,
      fontSize: "1.8em",
      fontWeight: 500,
      lineHeight: 1,
    },
    h2: {
      letterSpacing: "-0.02em",
      marginTop: 0,
      marginBottom: 0,
      fontSize: "1.6em",
      fontWeight: 600,
      lineHeight: 1,
    },
    h3: {
      letterSpacing: "-0.015em",
      marginTop: 0,
      marginBottom: 0,
      fontSize: "1.4em",
      fontWeight: 500,
      lineHeight: 1,
    },
    h4: {
      letterSpacing: "-0.01em",
      marginTop: 0,
      marginBottom: 0,
      fontSize: "1.2em",
      fontWeight: 500,
      lineHeight: 1,
    },
    h5: {
      marginTop: 0,
      marginBottom: 0,
      fontSize: "1em",
      fontWeight: 500,
      lineHeight: 1,
    },
    h6: {
      textTransform: "uppercase",
      marginTop: 0,
      marginBottom: 0,
      fontSize: "0.75em",
      fontWeight: 300,
      lineHeight: 1,
    },
    body1: {
      fontSize: "0.6em",
      marginBottom: 0,
    },
    body2: {
      fontSize: "0.65em",
      lineHeight: 1.8,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 479,
      md: 767,
      lg: 991,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#222222",
          backgroundColor: "#f7fafa",
          fontFamily: "Inter, sans-serif",
          fontSize: "2vw",
          lineHeight: 1.5,
          minHeight: "100%",
        },
      },
    },
    // MuiLink: {
    //   defaultProps: {
    //     underline: "none",
    //   },
    //   styleOverrides: {
    //     root: {
    //       textDecoration: "none",
    //       "&:hover": {
    //         textDecoration: "none",
    //       },
    //     },
    //   },
    //   // ONLY NEW ADDITION BELOW
    //   variants: [
    //     {
    //       props: { variant: "nav" },
    //       style: {
    //         opacity: 0.5,
    //         transition: "opacity 0.3s",
    //         "&:hover, &.active": {
    //           opacity: 1,
    //         },
    //       },
    //     },
    //   ],
    // },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "0.3em",
          fontSize: "0.9em",
          padding: "1em 1.5em",
          lineHeight: 1,
          transition: "background-color 0.3s",
        },
        contained: {
          backgroundColor: "#222222",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#444444",
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "@media (min-width: 767px)": {
            paddingLeft: "10%",
            paddingRight: "10%",
          },
        },
      },
    },
  },
});

// Responsive typography
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3em",
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.4em",
  },
};

// Additional responsive styles
theme.components.MuiCssBaseline.styleOverrides = {
  ...theme.components.MuiCssBaseline.styleOverrides,
  "@media (max-width: 767px)": {
    body: {
      fontSize: "2.5vw",
    },
  },
  "@media (max-width: 479px)": {
    body: {
      fontSize: "4vw",
    },
  },
};

export default theme;
